import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p><strong parentName="p">{`補足：`}</strong></p>
      <p><strong parentName="p">{`Q:「ボリューム下げる」というのは、トラックのフェーダーで下げるのですか？それとも、EQのゲインで下げるのですか？`}</strong></p>
      <p>{`A:　EQのゲインで下げるべきです。なぜなら、トラックのフェーダーを変えてしまうと、最初に決めたはずの楽器同士のバランスが狂ってしまうからです。`}</p>
      <p>{`もちろん、EQによって音量が上がった分だけフェーダーを下げれば、最初と同じ音量を保っているでしょう。しかし、今後「EQのかかり具合を確認したい」などでEQをバイパスする必要が出てくると、途端に狂ったバランスで再生され始めます。なぜなら、EQで上がったはずの「音量変化」もバイパスされるので、「下げてしまったフェーダー」の音量で再生されるからです。`}</p>
      <p>{`これでは、まともに作業できませんし、元に戻すこともできません。`}</p>
      <p><strong parentName="p">{`Q2:「コンプでゲインを上げて」「EQでゲインを下げた」というのは、二度手間ではありませんか？`}</strong></p>
      <p>{`A:　二度手間ではありません。`}<br parentName="p"></br>{`
`}{`なぜなら、「圧縮してゲインを上げる」と「ブーストしてゲインを下げる」というのは、どちらも「それで１つの操作」だからです。`}</p>
      <p>{`例えば、もしコンプで「圧縮するだけ」で、ゲインを上げないとしたら、それは単に「なんか音がちっちゃくなった」という結果になります。また、もしEQで「ブーストするだけ」で、ゲインを下げないとしたら、それは「なんか音がでっかくなった」という結果になります。これでは、最初の決めたはずの「大きさ」が狂ってしまいます。`}<br parentName="p"></br>{`
`}{`このように、コンプやEQを使うたびに、大前提である「ボリューム」は保つ必要があるので、ゲイン操作は毎回必須なのです。`}</p>
      <p>{`ですから、「コンプでゲインを上げて」「EQでゲインを下げた」というのは、一見二度手間に見えるかもしれませんが、`}</p>
      <ul>
        <li parentName="ul">{`それぞれが１セットである`}</li>
        <li parentName="ul">{`両者は「コンプ」と「EQ」という、全く別の作業である`}</li>
      </ul>
      <p>{`という理由で、二度手間にはなりません。`}<br parentName="p"></br>{`
`}{`もちろん、二度手間ではなく別の作業なので、「コンプで上げた量」と「EQで下げた量」は一致しません。（たまたま一致することはあります）`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      